import styled from '@emotion/styled'
import {
  ContentContainer,
  Heading,
  PageContainer,
  Stack,
  StyledPrimaryButton,
  StyledSecondaryButton,
  Text,
  Threshold,
} from 'fitify-ui-onboarding/src/components'
import NativeImage from 'fitify-ui-onboarding/src/components/Image/NativeImage'
import { useAuth } from 'fitify-ui-onboarding/src/hooks/useAuth'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingImageData, getImageProps } from 'utils/images'

import { HomePageProps } from '../types'

const StyledText = styled(Text)`
  ${(props) => props.theme.digitalV4 && 'color: var(--c-blue75)'}
`

export default function Page({ t, actions, initialLocale }: HomePageProps) {
  const { isLoggedIn } = useAuth()
  const { i18n } = useTranslation()

  const imageProps = useMemo<OnboardingImageData>(() => {
    const imageProps = getImageProps(
      'home',
      'youngCoupleCroppedLocalized',
      initialLocale || i18n.language
    ) as OnboardingImageData
    return imageProps
  }, [i18n.language, initialLocale])

  return (
    <PageContainer>
      <ContentContainer xs="1.5rem" md="2rem" removePadding>
        <Stack xs="0.5rem">
          <Heading>{t('welcome_title')}</Heading>
          <Text color="blue75">{t('welcome_subtitle')}</Text>
        </Stack>

        <NativeImage
          {...imageProps}
          alt="Homepage couple"
          isResponsive
          wrapperStyles={{ flex: '1' }}
          style={{
            backgroundColor: 'var(--c-blue-base)',
            borderRadius: '16px',
          }}
        />
        <Threshold hasBackground={false}>
          <Stack xs={20} md={24} align="center">
            <StyledPrimaryButton onClick={actions.handleContinue}>
              {t('btn_continue')}
            </StyledPrimaryButton>

            {!isLoggedIn && (
              <Stack xs={16} align="center">
                <StyledText>{t('welcome_have_account_question')}</StyledText>
                <StyledSecondaryButton size="md" onClick={actions.handleLogin}>
                  {t('email_login_forgot_btn_sign_in')}
                </StyledSecondaryButton>
              </Stack>
            )}
          </Stack>
        </Threshold>
      </ContentContainer>
    </PageContainer>
  )
}
